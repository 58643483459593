import { all } from 'redux-saga/effects';

import { userSagas } from './user/userSagas';
import { skipOnBoarding } from './user/skipOnboarding';
import { frontChat } from './user/frontChat';
import { validatePayment } from './payment/validatePayment';
import { skipTrial } from './payment/skipTrial';
import { setPaymentErrorCodeToReteno } from './payment/sendPaymentFailToReteno';
import { initOneClickPayment, handleSuccessOneClickWatcher } from './payment/oneClickPayment';
import { initSolidPayment } from './payment/initSolid';
import { initPayPal } from './payment/initPayPal';
import { initPaymentGenerator } from './payment/initPaymentGenerator';
import { initBrainTree } from './payment/initBrainTree';
import { initBankCard } from './payment/initBankCard';
import { getMerchantData } from './payment/getMerchantData';

export default function* rootSaga() {
    yield all([
        ...initSolidPayment,
        ...initBankCard,
        ...initPayPal,
        ...validatePayment,
        ...initOneClickPayment,
        ...userSagas,
        ...frontChat,
        ...initPaymentGenerator,
        ...initBrainTree,
        ...setPaymentErrorCodeToReteno,
        ...skipOnBoarding,
        ...skipTrial,
        ...handleSuccessOneClickWatcher,
        ...getMerchantData,
    ]);
}
