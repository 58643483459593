import { takeLatest, put, all } from 'redux-saga/effects';

import api from 'apiSingleton';

import * as actionTypes from 'redux/payment/actionTypes';
import { getMerchantData as getMerchantDataAction, setLoadingStatus, setMerchantData } from 'redux/payment/actions';

import { MerchantDataResponseType } from 'types/payments/paymentApiInterfaces';

export function* getMerchantDataSaga({
    payload: { productType, ...payload },
}: ReturnType<typeof getMerchantDataAction>) {
    try {
        const isLifeTime = productType !== 'subscription';

        yield put(setLoadingStatus(true));

        const merchantData: MerchantDataResponseType = yield api.payment.getMerchantData({
            ...payload,
            ...(isLifeTime && { product_id: null }),
        });

        yield all([
            put(
                setMerchantData({
                    merchant: merchantData?.merchant,
                    signature: merchantData?.signature,
                    paymentIntent: merchantData?.payment_intent,
                })
            ),
            put(setLoadingStatus(false)),
        ]);
    } catch (e) {
        throw e;
    }
}

export const getMerchantData = [takeLatest(actionTypes.GET_MERCHANT_DATA, getMerchantDataSaga)];
